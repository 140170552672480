.Login .MuiInput-underline:before {
  border-bottom: 1px solid #fff !important;
}

.Login .MuiInput-underline:after {
  border-bottom: 1px solid #009dbc !important;
}

.Login .MuiInputBase-input:-webkit-autofill,
.Login .MuiInputBase-input:-webkit-autofill:hover,
.Login .MuiInputBase-input:-webkit-autofill:focus {
  border: none;
  -webkit-text-fill-color: #fff;
  color: #fff;
  transition: background-color 5000s ease-in-out 0s;
}

.Login .MuiInputBase-input::placeholder {
  color: #fff;
  opacity: 0.7;
}

.Mui-focused fieldset {
  border-color: #009dbc !important;
}

.CreateAccount .datepicker {
   width: 100%;
}

.CreateAccount .react-datepicker__input-container {
  width: calc(100% - 40px) !important;
  margin: 20px;
}
