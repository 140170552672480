.ReportsReviews {
  max-width: 1300px;
  margin: 0 auto;
}

.MuiOutlinedInput-input {
  padding: 11.5px 14px !important;
}

a.report-link {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

.react-datepicker__month-text {
  padding: 10px;
}

.MuiAutocomplete-option[data-focus="true"]  {
  background-color: #ccc !important;
}

.MuiTableBody-root tr:last-child .MuiTableCell-root {
  border-bottom: 0 !important;
}
