html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}

html, body {
  height: 100%;
}

#root, .App {
  position: relative;
  min-height: 100vh;
  overflow: auto;
}

.app-body {
  height: calc(100% - 100px);
  padding-top: 20px;
  padding-bottom: 2.5rem;
}

body {
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  color: #736f6f;
  font-size: 16px;
}

footer#footer {
  position: absolute;
  left: 0;
  bottom: 10px;
  width: 100%;
  text-align: center;
  height: 2.5rem;
  font-size: 14px;
}

/* links */
a, a:focus, a:visited, a:active {
  text-decoration: none;
  word-break: break-word;
}

/* ViewReport - ReportText */
@media (min-width: 1010px) {
  p {
    line-height: 1.2;
  }
}

@media (min-width: 1300px) {
  p {
    line-height: 1.4;
  }
}

@media (min-width: 1600px) {
  p {
    line-height: 1.5;
  }
}
